import React from "react";
import styled from "styled-components";
import { Text } from "basis";
import { BREAKPOINT, COLOR } from "../../../components/theme";
import { Column, Icon } from "../../../components";

const StyledHeadingCellContainer = styled.div`
  border: 1px solid ${COLOR.WHITE};
  padding: 10px;
  background: ${COLOR.GREY_50};
  justify-content: space-between;

  &.active {
    background: ${COLOR.DARK_SKY_BLUE};
  }

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${BREAKPOINT.SIZE_TABLET}) {
    display: flex;
  }
`;

export const MerchantTableHeadings = ({ onSort = () => { }, sortTerm, noSort, sortType }) => {
  const HeadingCell = ({ title, sortName }) => (
    <StyledHeadingCellContainer
      onClick={() => onSort(sortName)}
      className={sortTerm === sortName && "active"}
    >
      <Text align="left" textStyle="body2" color="white">
        <strong>{title}</strong>
      </Text>

      {sortName && (
        <Icon
          padding
          type={sortTerm === sortName ? `${sortType}Sort` : "sortDefault"}
          size="25px"
        />
      )}
    </StyledHeadingCellContainer>
  );

  return (
    <Column
      template="1fr"
      templateTablet="2fr 2fr 2fr"
    >
      <HeadingCell title="Merchant Name" sortName="val" />
      <HeadingCell title="Merchant Id" sortName="id" />
      <HeadingCell  title="Actions" />
    </Column>
  );
};
